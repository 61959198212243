import React from 'react';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Column, Columns, Section, Level, Left, Right, Item } from '../components/bulma';

export default function ContentSection({ items, box }) {
	let i = 0;
	return (
		<Section>
			{items &&
				items.map(({ image, icon, title, text }) => (
					<Box>
						<Columns vertical key={title}>
							{Boolean(++i % 2) && (
								<Column>
									{icon ? (
										<span className="fa-layers fa-fw fa-10x">
											<FontAwesomeIcon icon="square" color="#EEE" />
											<FontAwesomeIcon icon={icon} color="#444" transform="shrink-6" />
										</span>
									) : (
										<PreviewCompatibleImage imageInfo={image} />
									)}
								</Column>
							)}
							<Column>
								<p className="title is-size-1">{title}</p>
								<p className="subtitle">{text}</p>
							</Column>
							{Boolean(i % 2) || (
								<Column>
									{icon ? (
										<span className="fa-layers fa-fw fa-10x">
											<FontAwesomeIcon icon="square" color="#EEE" />
											<FontAwesomeIcon icon={icon} color="#444" transform="shrink-6" />
										</span>
									) : (
										<PreviewCompatibleImage imageInfo={image} />
									)}
								</Column>
							)}
						</Columns>
					</Box>
				))}
		</Section>
	);
}

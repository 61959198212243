import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Box, Column, Columns, Section } from '../components/bulma';

export default function BlogHeadlines({ box }) {
	const data = useStaticQuery(BLOG_HEADLINES_QUERY);
	const { edges: posts } = data.allMarkdownRemark;

	return (
		<Columns>
			{posts.map(({ node: post }) => (
				<Column key={post.id}>
					<Box>
						<div className="has-text-centered">
							<figure className="image">
								{post.frontmatter.featuredimage ? (
									<div className="featured-thumbnail">
										<PreviewCompatibleImage
											imageInfo={{
												image: post.frontmatter.featuredimage,
												alt: `featured image thumbnail for post ${post.title}`
											}}
										/>
									</div>
								) : null}
							</figure>
						</div>
						<br />

						<Link className="title is-size-5" to={post.fields.slug}>
							{post.frontmatter.title}
						</Link>
						<br />
						<br />
						<p className="is-size-6">{post.excerpt}</p>

						<br />
						<br />
						<Link to={post.fields.slug} className="button is-small is-primary">
							Continue
						</Link>
					</Box>
				</Column>
			))}
		</Columns>
	);
}

const BLOG_HEADLINES_QUERY = graphql`
	query BlogHeadlinesQuery {
		allMarkdownRemark(
			limit: 20
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
		) {
			edges {
				node {
					excerpt(pruneLength: 400)
					id
					fields {
						slug
					}
					frontmatter {
						title
						templateKey
						date(formatString: "MMMM DD, YYYY")
						featuredpost
						featuredimage {
							childImageSharp {
								fluid(maxWidth: 120, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;

import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import { Box, Section } from '../components/bulma';
// import Slideshow from '../components/Slideshow';
import Blurbs from '../components/Blurbs';
import Pitch from '../components/Pitch';
import ContentSection from '../components/ContentSection';
import BlogSection from '../components/BlogSection';
import CTA from '../components/CTA';

export default function IndexPage({ data }) {
	const { markdownRemark: page } = data;
	const { image, heading, subheading, sections, blurbs, pitch, final } = page.frontmatter;

	return (
		<Layout>
			<IndexPageTemplate
				image={image}
				heading={heading}
				subheading={subheading}
				blurbs={blurbs}
				pitch={pitch}
				sections={sections}
				final={final}
			/>
		</Layout>
	);
}

export const IndexPageTemplate = ({ image, heading, subheading, pitch, sections, blurbs, final }) => (
	<div>
		<Hero size="medium" title={heading} subtitle={subheading} image={image} signup />
		<Section>
			<h3 className="title is-size-1 has-text-centered">Everything you need to run your business</h3>
			<Blurbs box items={blurbs} />
		</Section>

		{/* <Pitch pitch={pitch} /> */}

		<ContentSection items={sections} box />

		<Section>
			<Box>
				<CTA />
			</Box>
		</Section>

		<Section>
			<h3 className="title is-size-1 has-text-centered">Business Ideas from Ruby</h3>
			<BlogSection />
		</Section>
	</div>
);

export const indexPageQuery = graphql`
	query IndexPage {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				heading
				subheading
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				pitch {
					title
					text
					cta
					link
				}
				blurbs {
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
					title
					text
				}
				sections {
					title
					text
					color
					side
					image {
						childImageSharp {
							fluid(maxWidth: 500, quality: 64) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				cta {
					title
					text
					label
				}
			}
		}
	}
`;
